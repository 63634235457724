import moment from "moment";
import {
  Badge,
  Button,
  Container,
  Modal,
  Row,
  Col,
  Table,
  Form,
} from "react-bootstrap";
import { Component } from "react";
import { AnimationOnScroll } from "./AnimationOnScroll";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faAnglesUp, faAnglesDown } from "@fortawesome/free-solid-svg-icons";

import "./Main.css";

const initialState = {
  leaveTime: null,
  arriveTime: null,
  places: [],
  currentType: null,
  currentTypeBaseDuration: null,
  currentManual: null,
  currentDelIndex: null,
  currentAddIndex: null,
  lastOpIndex: null,
  lastOp: null,
  manualType: "Other",
};

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
    this.currentTime = null;
    this.isDisabled = false;
  }

  persistState = () => {
    localStorage.setItem(
      "state",
      JSON.stringify({
        ...this.state,
        lastOpIndex: null,
        lastOp: null,
      })
    );
  };

  resetState = () => {
    const isConfirm = window.confirm("Clear all records and data?");
    if (isConfirm) {
      this.setState({
        ...initialState,
      });
      this.currentTime = null;
      this.isDisabled = false;
    }
  };

  componentDidMount = () => {
    var state = JSON.parse(localStorage.getItem("state"));

    if (state === null || !moment(state?.leaveTime).isSame(moment(), "day"))
      return;

    this.setState({
      ...state,
      leaveTime: state.leaveTime ? moment(state.leaveTime) : null,
      arriveTime: state.arriveTime ? moment(state.arriveTime) : null,
    });
  };

  handleRealTime = (type) => {
    this.setState(
      {
        [type]: moment(),
      },
      () => {
        this.persistState();
      }
    );
  };

  handleAddPlace = (type, baseDuration, numOfEe) => {
    if (this.isDisabled) return;

    this.isDisabled = true;

    switch (type) {
      default:
        const length = this.state.places.length;
        this.setState(
          {
            places: [...this.state.places, { type, baseDuration, numOfEe }],
            currentType: null,
            currentTypeBaseDuration: null,
            currentManual: null,
            currentAddIndex: length,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  currentAddIndex: null,
                  lastOpIndex: length,
                  lastOp: "Add",
                },
                () => {
                  this.persistState();
                }
              );
            }, 1000);
          }
        );
        break;
    }

    setTimeout(() => {
      this.isDisabled = false;
    }, 800);
  };

  handleTime = (timeType, e) => {
    this.setState(
      {
        [timeType]: moment(e.target.value, "HH:mm"),
      },
      () => {
        this.persistState();
      }
    );
  };

  calcKey = () => {
    return `${this.state.leaveTime?.unix()}${this.state.arriveTime?.unix()}${
      this.state.places.length
    }`;
  };

  render = () => {
    console.log(this.state.places);
    return (
      <>
        <Container>
          <Row className="mt-3">
            <Col>
              <Badge bg="secondary">Leave</Badge>
              &nbsp;
              <strong>{this.state.leaveTime?.format("hh:mm a")} </strong>
              <Form.Control
                type="time"
                onChange={(e) => this.handleTime("leaveTime", e)}
              />
            </Col>
            <Col>
              <Badge bg="secondary">Arrive</Badge>
              &nbsp;
              <strong>{this.state.arriveTime?.format("hh:mm a")} </strong>
              <Form.Control
                type="time"
                onChange={(e) => this.handleTime("arriveTime", e)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Badge bg="secondary">Now</Badge>
              &nbsp;
              <strong>{this.renderLastEndTime()}</strong>
            </Col>
            <Col>
              <Badge bg="secondary">Travel Time</Badge>
              &nbsp;
              <strong>
                {this.state.arriveTime?.diff(this.state.leaveTime, "minutes") <
                0 ? (
                  <span
                    className="text-danger blink"
                    style={{ backgroundColor: "yellow" }}
                  >
                    {this.state.arriveTime?.diff(
                      this.state.leaveTime,
                      "minutes"
                    )}{" "}
                    mins
                  </span>
                ) : (
                  (this.state.arriveTime?.diff(
                    this.state.leaveTime,
                    "minutes"
                  ) ?? "-") + " mins"
                )}{" "}
              </strong>
            </Col>
          </Row>
          <Row>
            <Col key={this.calcKey()} className="col-last">
              <div>
                <Badge bg="primary">Now+Travel</Badge>
                &nbsp;
                <strong className="d-inline-block animate__animated animate__heartBeat">
                  <span className="double-underline">
                    {this.renderLastAndTravelTime()}
                  </span>
                </strong>
              </div>
              <div>
                <Badge bg="primary">Last</Badge>
                &nbsp;
                <strong className="d-inline-block animate__animated animate__heartBeat">
                  <span className="double-underline">
                    {this.state.places.length > 0
                      ? `${this.state.places?.at(-1)?.type} (${
                          this.state.places?.at(-1)?.baseDuration +
                          this.state.places?.at(-1)?.numOfEe
                        })`
                      : ""}
                  </span>
                </strong>
              </div>
            </Col>
            <Col>
            <Button
                className="float-end"
                variant="danger"
                size="sm"
                onClick={() => window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })}
              >
                <FontAwesomeIcon icon={faAnglesDown} />
              </Button>
              <Button
                className="float-end me-2"
                variant="danger"
                size="sm"
                onClick={() => this.resetState()}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            </Col>
          </Row>
          <Row className="mt-3">
            {!this.state.leaveTime && (
              <Col>
                <Button
                  size="lg"
                  variant="primary"
                  className="cmd-btn animate__animated animate__fadeIn"
                  onClick={() => this.handleRealTime("leaveTime")}
                >
                  Leave
                </Button>
              </Col>
            )}
            {this.state.leaveTime && !this.state.arriveTime && (
              <Col>
                <Button
                  size="lg"
                  variant="secondary"
                  className="cmd-btn animate__animated animate__fadeIn"
                  onClick={() => this.handleRealTime("arriveTime")}
                >
                  Arrive
                </Button>
              </Col>
            )}
          </Row>
          {this.state.leaveTime && this.state.arriveTime && (
            <div className="animate__animated animate__fadeIn">
              <Row className="mt-3">
                <Col>
                  <Button
                    size="lg"
                    variant="outline-primary"
                    className="cmd-btn"
                    onClick={() => this.handleAddPlace("C&C", 1, null)}
                  >
                    C&amp;C (1)
                  </Button>
                </Col>
                <Col>
                  <Button
                    size="lg"
                    variant="outline-primary"
                    className="cmd-btn"
                    onClick={() => this.handleAddPlace("Locked", 1, null)}
                  >
                    Locked (1)
                  </Button>
                </Col>
                <Col>
                  <Button
                    size="lg"
                    variant="outline-primary"
                    className="cmd-btn"
                    onClick={() => this.handleAddPlace("VV", 1, null)}
                  >
                    VV (1)
                  </Button>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Button
                    size="lg"
                    variant="outline-warning"
                    className="cmd-btn"
                    onClick={() => this.handleAddPlace("No e/e", 7, null)}
                  >
                    No e/e (7)
                  </Button>
                </Col>
                <Col>
                  <Button
                    size="lg"
                    variant="outline-warning"
                    className="cmd-btn"
                    onClick={() =>
                      this.handleAddPlace("No e/e S/P with 1", 6, null)
                    }
                  >
                    No e/e S/P with 1 (6)
                  </Button>
                </Col>
                <Col>
                  <Button
                    size="lg"
                    variant="outline-warning"
                    className="cmd-btn"
                    onClick={() =>
                      this.handleAddPlace("No e/e S/P with 2", 5, null)
                    }
                  >
                    No e/e S/P with 2 (5)
                  </Button>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Button
                    size="lg"
                    variant="outline-secondary"
                    className="cmd-btn"
                    onClick={() => this.handleAddPlace("Denied e/e", 9, null)}
                  >
                    Denied e/e (9)
                  </Button>
                </Col>
                <Col>
                  <Button
                    size="lg"
                    variant="outline-secondary"
                    className="cmd-btn"
                    onClick={() => this.handleAddPlace("No e/e seen", 10, null)}
                  >
                    No e/e seen (10)
                  </Button>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Button
                    size="lg"
                    variant="outline-info"
                    className="cmd-btn"
                    onClick={() => this.handleShow("In order", 14)}
                  >
                    In order (15+)
                  </Button>
                </Col>
                <Col>
                  <Button
                    size="lg"
                    variant="outline-info"
                    className="cmd-btn"
                    onClick={() => this.handleShow("In order (NC / Minor)", 15)}
                  >
                    <span className="small-btn-font">
                      In order (NC / Minor) (16+)
                    </span>
                  </Button>
                </Col>
                <Col>
                  <Button
                    size="lg"
                    variant="outline-danger"
                    className="cmd-btn"
                    onClick={() => this.handleShow("Other", 0)}
                  >
                    Other (Any)
                  </Button>
                </Col>
              </Row>
            </div>
          )}

          {
            <Table striped bordered hover className="mt-3 mb-3">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Type</th>
                  <th>e/e</th>
                  <th>Time</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>{this.renderHistory()}</tbody>
            </Table>
          }
          <div>
            <AnimationOnScroll
              animateOnce={false}
              isBtn={true}
              animateIn="animate__animated animate__fadeIn"
            >
              <Button
                variant="outline-info"
                className="float-end mb-4 me-2"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <FontAwesomeIcon icon={faAnglesUp} />
              </Button>
            </AnimationOnScroll>
          </div>
        </Container>

        <>
          <Modal
            show={
              this.state.currentType !== null &&
              this.state.currentType !== "Other"
            }
            onHide={this.handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {this.state.currentType} (
                {this.state.currentTypeBaseDuration + 1}+)
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                {[...Array(12).keys()].map((i) => {
                  return (
                    <Col key={i} sm={4}>
                      <Button
                        size="lg"
                        variant="outline-success"
                        className="mb-3 cmd-btn"
                        onClick={() =>
                          this.handleAddPlace(
                            this.state.currentType,
                            this.state.currentTypeBaseDuration,
                            i + 1
                          )
                        }
                      >
                        {i + 1} e/e (
                        {this.state.currentTypeBaseDuration + i + 1})
                      </Button>
                    </Col>
                  );
                })}
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="me-auto"
                variant="secondary"
                onClick={this.handleClose}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>

        <>
          <Modal
            show={this.state.currentType === "Other"}
            onHide={this.handleClose}
            centered
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>{this.state.currentType} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Manual (type)</Form.Label>
                  <Form.Control
                    type="text"
                    className="mb-3"
                    value={this.state.manualType || ""}
                    onChange={(e) => {
                      this.setState({
                        manualType: e.target.value,
                      });
                    }}
                  />
                  <Form.Label>Manual (mins)</Form.Label>
                  <Form.Control
                    type="number"
                    value={this.state.currentManual || ""}
                    onChange={(e) => {
                      this.setState({
                        currentManual: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                className="me-auto"
                onClick={() => {
                  if (Number.isInteger(+this.state.currentManual)) {
                    this.handleAddPlace(
                      this.state.manualType,
                      +this.state.currentManual,
                      null
                    );
                  } else {
                    alert("Must be a number");
                  }
                }}
              >
                Add
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </>
    );
  };

  handleShow = (type, typeBaseDuration) => {
    this.setState({
      currentType: type,
      currentTypeBaseDuration: typeBaseDuration,
    });
  };

  handleClose = () => {
    this.setState({
      currentType: null,
      currentTypeBaseDuration: null,
      currentManual: null,
    });
  };

  calcLastEndTime = () => {
    this.currentTime = this.state.arriveTime?.clone();
    let lastEndTime = this.currentTime;
    this.state.places.forEach((place) => {
      lastEndTime
        ?.add(place.baseDuration, "m")
        ?.add(place.numOfEe, "m")
        ?.add(1, "m");
    });
    if (this.state.places.length > 0) lastEndTime?.subtract(1, "m");
    return lastEndTime;
  };

  renderLastEndTime = () => {
    return this.calcLastEndTime()?.add()?.format("hh:mm a");
  };

  renderLastAndTravelTime = () => {
    return this.calcLastEndTime()
      ?.add(this.state.arriveTime?.diff(this.state.leaveTime, "m"), "m")
      ?.format("hh:mm a");
  };

  handleDelete = (i) => {
    const isConfirm = window.confirm("Confirm to delete #" + (i + 1) + " ?");
    if (isConfirm) {
      this.setState(
        {
          currentDelIndex: i,
        },
        () => {
          setTimeout(() => {
            this.state.places.splice(i, 1);
            this.setState(
              {
                state: this.state.places,
                currentDelIndex: null,
                lastOpIndex: i,
                lastOp: "Delete",
              },
              () => {
                this.persistState();
              }
            );
          }, 1000);
        }
      );
    }
  };

  getAnimateClassNames = (i) => {
    // return "opacity-100";

    // console.log(this.state.currentDelIndex, this.state.currentAddIndex);
    // console.log(this.state.lastOp);
    // console.log(this.state.lastOpIndex, this.state.places?.length - 1);
    // console.log(this.state.lastOpIndex < this.state.places?.length - 1);

    console.log(this.state.lastOp, i, this.state.lastOpIndex);
    console.log(this.state.lastOp === "Delete" && i >= this.state.lastOpIndex);

    if (this.state.currentDelIndex === i) return "animate__fadeOutLeft";
    if (this.state.currentAddIndex === i) return "animate__fadeInLeft";
    if (this.state.lastOp === "Delete" && i >= this.state.lastOpIndex)
      return "opacity-100";

    return "animate__fadeInLeft";

    // return "animate__fadeInLeft";
  };

  renderHistory = () => {
    this.currentTime = this.state.arriveTime?.clone();
    return this.state.places.map((place, i) => {
      return (
        <AnimationOnScroll
          key={i}
          animateOnce={true}
          isDel={this.state.currentDelIndex === i}
          lastOp={this.state.lastOp}
          animateIn={`${this.getAnimateClassNames(i)}`}
        >
          <td>{i + 1}</td>
          <td>
            {place.type} (
            {place.numOfEe !== null
              ? `${place.baseDuration + 1}+`
              : place.baseDuration}
            )
          </td>
          <td>{place.numOfEe}</td>
          <td>{place.baseDuration + place.numOfEe}</td>
          <td>{this.currentTime?.format("hh:mm a")}</td>
          {(() => {
            this.currentTime
              ?.add(place.baseDuration, "m")
              ?.add(place.numOfEe, "m");
            return <td>{this.currentTime?.format("hh:mm a")}</td>;
          })()}
          {(() => {
            this.currentTime?.add(1, "m");
          })()}
          <td>
            <Button
              variant="danger"
              size="sm"
              className="float-end"
              onClick={(e) => this.handleDelete(i)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </td>
        </AnimationOnScroll>
      );
    });
  };
}

export default Main;
