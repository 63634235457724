import Main from './Main';

import './App.css';


function App() {
  return (
    <Main />
  );
}

export default App;
